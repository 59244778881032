<template>
  <div class="card mx-sm-5" v-if="order_data.id != null">
    <div class="card-body">
      <p class="m-0">Summary Payment</p>
      <div>
        <div class="text-center mt-3">
          <img src="@/assets/check_icon.png">
          <p class="m-0 mt-3 text-primary font-weight-bold">{{ order_data.status_payment == 'unpaid' ? 'Wait for Payment' : 'Paid' }}</p>
          <p class="m-0">{{ order_data.created_at_moment.format('DD MMMM YYYY') }}</p>
          <h4 class="m-0">Rp. {{ order_data.total_price.toLocaleString(base.locale_string) }}</h4>
        </div>
        <hr>
        <div>
          <div>
            <p class="m-0">Payment Method</p>
            <div class="d-flex align-items-center mt-1">
              <img :src="order_data.payment_method.url_image" class="rounded-circle" style="height: 3rem; width: 3rem;">
              <p class="m-0 ml-3">{{ order_data.payment_method.name }}</p>
            </div>
          </div>
          <div class="d-flex justify-content-between mt-3">
            <p class="m-0">Invoice ID</p>
            <p class="m-0">{{ order_data.id }}</p>
          </div>
          <div class="d-flex justify-content-between mt-3" v-if="order_data.payment_method.code == 'qris' && order_data.url_payment != null">
						<p class="m-0">QR Code</p>
						<img :src="order_data.url_payment" style="width: 10rem;"/>
					</div>
					<div class="d-flex justify-content-between mt-3" v-if="order_data.va_no != null">
            <p class="m-0">VA Number</p>
            <p class="m-0">
              <i class="mr-2 mdi mdi-content-copy" style="cursor: pointer;" @click="onCopyClicked('va_no')"></i>
              {{ order_data.va_no }}
            </p>
          </div>
          <div class="d-flex justify-content-between mt-3" v-if="order_data.bill_key != null">
            <p class="m-0">Bill Key</p>
            <p class="m-0">
              <i class="mr-2 mdi mdi-content-copy" style="cursor: pointer;" @click="onCopyClicked('bill_key')"></i>
              {{ order_data.bill_key }}
            </p>
          </div>
          <div class="d-flex justify-content-between mt-3" v-if="order_data.biller_code != null">
            <p class="m-0">Biller Code</p>
            <p class="m-0">
              <i class="mr-2 mdi mdi-content-copy" style="cursor: pointer;" @click="onCopyClicked('biller_code')"></i>
              {{ order_data.biller_code }}
            </p>
          </div>
          <div class="d-flex justify-content-between mt-3" style="gap: .5rem;">
            <p class="m-0">Invoice Expired Date</p>
            <p class="m-0 text-right">{{ order_data.payment_expired_at_moment != null ? order_data.payment_expired_at_moment.format('DD MMMM YYYY HH:mm:ss') : '-' }}</p>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column flex-md-row mt-3" style="gap: 1rem;">
        <button class="btn btn-primary flex-fill" @click="onBackClicked">Back to Dashboard</button>
        <button class="btn btn-primary flex-fill" @click="onDownloadClicked">Download Invoice</button>
        <button class="btn btn-primary flex-fill" @click="onConfirmClicked" v-if="order_data.url_payment != null">Confirmation Payment</button>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';

import NoImage from '@/assets/no_image.png';

export default {
  components: {
  },
  data(){
    return{
      base: null,
      order_data: {},
    }
  },
  async mounted(){
    this.base = new Base()

    this.base.save_current_page('Summary Payment', false, 'mdi mdi-home')
    var order_data = await window.localStorage.getItem('order_data')
    order_data = JSON.parse(order_data)

    order_data.payment_method.url_image = order_data.payment_method.file_name != null ? this.base.host + "/media/payment-method?file_name=" + order_data.payment_method.file_name : NoImage
    order_data.created_at_moment = momentTZ.tz(order_data.created_at, this.base.locale_timezone)
    order_data.updated_at_moment = momentTZ.tz(order_data.updated_at, this.base.locale_timezone)
    if(order_data.payment_expired_at != null)
      order_data.payment_expired_at_moment = momentTZ.tz(order_data.payment_expired_at, this.base.locale_timezone)

    this.order_data = order_data
  },
  methods: {
    async onCopyClicked(type){
      try {
        await navigator.clipboard.writeText(this.order_data[type])
        alert('Copied');
      } catch($e) {
        alert('Cannot copy');
      }
    },
    async back_action(){
      var from = await window.localStorage.getItem('from')
      if(from == 'registration')
        this.base.back_page(true, 0)
      else if(from == 'team')
        this.base.back_page(true, 2)
    },
    onBackClicked(){
      this.back_action()
    },
    onConfirmClicked(){
      window.open(this.order_data.url_payment, '_blank')
    },
    onDownloadClicked(){
      window.location.href = '/order/detail?id=' + this.order_data.id
    },
  }
}
</script>

<style lang="scss">
</style>
