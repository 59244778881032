<template>
  <div>
    <div class="card">
      <div class="card-body">
        <ul class="nav nav-pills mb-3 overflow-auto flex-nowrap" id="myTab" role="tablist">
          <li class="nav-item flex-fill" role="presentation" v-for="(menu, index) in arr_menu" :key="index">
            <button class="nav-link w-100 text-nowrap" :class="index == selected_tab ? 'active' : ''" :id="menu.id + '-tab'" data-toggle="tab" :data-target="'#' + menu.id" type="button" role="tab" :aria-controls="menu.id" aria-selected="true" @click="onTabClicked(index)">{{ menu.name }}</button>
          </li>
        </ul>

        <!-- Tab panes -->
        <div class="tab-content">
          <div class="tab-pane" v-for="(menu, index) in arr_menu" :key="index" :class="index == selected_tab ? 'active' : ''" :id="menu.id" role="tabpanel" :aria-labelledby="menu.id + '-tab'">
            <component :is="menu.component" :team_data="team_data" :index="index" :selected_tab="selected_tab" @onForceReload="onForceReload" :first_time="first_time"/>
          </div>
        </div>

        <div class="mt-3 d-flex justify-content-between">
          <button class="btn btn-outline-primary" @click="onCancelClicked">Back</button>
        </div>
      </div>
    </div>

    <ModalEditTeam :team_data="team_data"/>
  </div>
</template>

<script>
import Base from '@/utils/base';
import momentTZ from 'moment-timezone';
import NoImage from '@/assets/no_image.png';

import GeneralInfo from '@/pages/team/component/general_info';
import ListPlayer from '@/pages/team/component/list_player';
import ListCoach from '@/pages/team/component/list_coach';
import ListMatch from '@/pages/team/component/list_match';
import ListOrder from '@/pages/team/component/list_order';
import ModalEditTeam from '@/pages/team/component/modal_edit_team';

export default {
  components: {
    'GeneralInfo': GeneralInfo,
    'ListPlayer': ListPlayer,
    'ListCoach': ListCoach,
    'ListMatch': ListMatch,
    'ListOrder': ListOrder,
    'ModalEditTeam': ModalEditTeam,
  },
  data(){
    return{
      base: null,
      arr_menu: [],
      selected_tab: 0,
      team_data: {},
      force_reload: false,
      first_time: true,
    }
  },
  watch: {
    team_data(val){
      if(val != null){
        this.first_time = false

				var arr_menu = []
				arr_menu.push(
					{
						id: 'general_info',
						name: 'General Info',
						component: 'GeneralInfo',
					},
					{
						id: 'list_player',
						name: 'List Player',
						component: 'ListPlayer',
					},
					{
						id: 'list_coach',
						name: 'List Coach',
						component: 'ListCoach',
					},
				)
				if(val.event_category_sport_category.event_category_sport.scoring_type == 'League' || val.event_category_sport_category.event_category_sport.scoring_type == 'Tournament')
					arr_menu.push(
						{
							id: 'list_match',
							name: 'List Match',
							component: 'ListMatch',
						},
					)
				arr_menu.push(
					{
						id: 'list_order',
						name: 'List Order',
						component: 'ListOrder',
					},
				)
				this.arr_menu = arr_menu
			}
    },
    force_reload(val){
      if(val){
        this.force_reload = false
        this.get_registration()
      }
    },
  },
  async mounted(){
    this.base = new Base()

    this.base.save_current_page('Team Detail', false, 'mdi mdi-file-document')
    this.get_registration()

    var tab_index = await window.localStorage.getItem('tab_index')
    if(tab_index != null){
			var context = this
			setTimeout(() => {
				context.selected_tab = tab_index
			}, 2000)

		}
  },
  methods: {
    onForceReload(){
      this.force_reload = true
    },
    onTabClicked(index){
      this.selected_tab = index
      window.localStorage.setItem('tab_index', index)
    },
    onCancelClicked(){
      this.base.back_page()
    },
    async get_registration(){
      var response = await this.base.request(this.base.url_api + "/event/registration?id=" + this.$route.query.id)

      if(response != null){
        if(response.status === "success"){
          response.data.url_image = response.data.file_name != null ? this.base.host + '/media/registration/team?file_name=' + response.data.file_name : NoImage
          response.data.event_category_sport_category.event_category_sport.start_date_moment = momentTZ.tz(response.data.event_category_sport_category.event_category_sport.start_date, this.base.locale_timezone)
          response.data.event_category_sport_category.event_category_sport.end_date_moment = momentTZ.tz(response.data.event_category_sport_category.event_category_sport.end_date, this.base.locale_timezone)
          this.team_data = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
</style>
